import { ClientService } from "../client-service/client-service";
import { StorageService } from "../models/storage-service";
import { PromiseSubject } from "../promise-subject/promise-subject";
import { IUserInfo, IUserSession } from "../models/user-info";
import { v5 as uuidv5 } from 'uuid';

export class UserAuthenticationService {

    private static instance: UserAuthenticationService;
    // used for generating email to id hash
    private static NAMESPACE = 'd15a4a20-74f1-11ed-a1eb-0242ac120002';

    private USER_SESSION_KEY = "profile-session";

    private userProfilePromise = new PromiseSubject<IUserSession | null>();

    constructor(
        private clientService: ClientService,
        private storageService: StorageService
    ) {
        if (UserAuthenticationService.instance) {
            return UserAuthenticationService.instance;
        }

        UserAuthenticationService.instance = this;

        this.init();
    }

    getUserInfo(): Promise<IUserInfo | null> {
        return this.userProfilePromise.getPromise();
    }

    isLoggedIn(): Promise<boolean> {
        return this.getUserInfo().then(user => !!user);
    }

    async logOut(): Promise<void> {
        await this.storageService.remove(this.USER_SESSION_KEY);

        // reload this page
        // TODO: this is hacky
        window.location.reload();
    }

    private async init(): Promise<void> {
        if (!this.clientService.getConfig().userProfile.enabled) {
            this.userProfilePromise.resolve(null);
            return;
        }

        this.loadUserSession();
    }

    private async loadUserSession() {
        // if we have a query param with a code, that means we've probably just logged in
        const queryAuthCode = this.getQueryParam('code');
        if (queryAuthCode) {
            await this.loginFromCode(queryAuthCode);
        } else {
            // load user from storage
            const saved = await this.storageService.get<any>(this.USER_SESSION_KEY);
        }

    }

    private async loginFromCode(code: string): Promise<void> {

    }


    // NOTE: this CANNOT change without an ID migration!
    private deriveId(email: string): string {
        return uuidv5(email, UserAuthenticationService.NAMESPACE);
    }

    private getQueryParam(param: string) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(param);
    }
}
import { Client, ClientConfig } from "../models/client-config";
import { ANDROID_APP_CONFIG } from "./android-app-config";
import { ANDROID_APP_LOCAL_CONFIG } from "./android-app-local-config";
import { BOOK_CONFIG } from "./book-config";
import { IOS_APP_CONFIG } from "./ios-app-config";
import { WEB_BROWSER_CONFIG } from "./web-browser-config";
import BID from "./b.json";
import { IOS_APP_LOCAL_CONFIG } from "./ios-app-local-config";
import { LOCALHOST_CONFIG } from "./localhost-app-config";
import { STEAM_APP_CONFIG } from "./steam-app-config";
import { EventEmitter } from "eventemitter3";
import { PromiseSubject } from "../promise-subject/promise-subject";

export class ClientService {

    // hacky singleton implementation
    private static instance: ClientService;

    private eventEmitter = new EventEmitter();

    private client!: Client;
    private config!: ClientConfig;

    private _domInteractive = new PromiseSubject<boolean>();

    constructor() {
        if (ClientService.instance) {
            return ClientService.instance;
        }

        const clientType = new URLSearchParams(window.location.search)
            .get("c") || null;

        switch (clientType) {
            case Client.androidApp:
                this.client = Client.androidApp;
                this.config = ANDROID_APP_CONFIG;
                break;
            case Client.androidAppLocal:
                this.client = Client.androidAppLocal;
                this.config = ANDROID_APP_LOCAL_CONFIG;
                break;
            case Client.iosApp:
                this.client = Client.iosApp;
                this.config = IOS_APP_CONFIG;
                break;
            case Client.iosAppLocal:
                this.client = Client.iosAppLocal;
                this.config = IOS_APP_LOCAL_CONFIG;
                break;
            case Client.webBrowserBook:
                this.client = Client.webBrowserBook;
                this.config = BOOK_CONFIG;
                break;
            case Client.steamApp:
                this.client = Client.steamApp;
                this.config = STEAM_APP_CONFIG;
                break;
            default:
                if (window.location.hostname.includes("hexakai.com")) {
                    this.client = Client.webBrowser;
                    this.config = WEB_BROWSER_CONFIG;
                } else {
                    this.client = Client.localhost;
                    this.config = LOCALHOST_CONFIG;
                }

                break;
        }

        ClientService.instance = this;
        console.log("[ClientService] client is " + this.client);

        this.initDomInteractive();
        this.resolveWhenDomInteractive().then(() => {
            this.interceptAppHref();
            this.updateBodyStyles();
            this.updateFooterIdText();
        });
    }

    private initDomInteractive() {
        if (document.readyState === 'interactive' || document.readyState === 'complete') {
            this._domInteractive.resolve(true);
            return;
        }

        document.addEventListener('readystatechange', () => {
            if (document.readyState === 'interactive') {
                this._domInteractive.resolve(true);
            }
        });

        document.addEventListener('DOMContentLoaded', () => {
            this._domInteractive.resolve(true);
        });
    }

    resolveWhenDomInteractive(): Promise<boolean> {
        return this._domInteractive.getPromise();
    }

    getConfig(): ClientConfig {
        // hacky way to prevent mutation related issues
        // TODO: lock / freeze this object instead
        return JSON.parse(JSON.stringify(this.config));
    }

    getClient(): Client {
        return this.client;
    }

    setIsDailyContext(doHighlight: boolean): void {
        console.log("[ClientService] showing or hiding daily highlight", doHighlight);

        const dailyEl = document.querySelector("#daily-menu");
        const url = new URL(window.location.href);

        if (doHighlight) {
            dailyEl?.classList.add("daily-puzzle-active");
            //url.searchParams.set("d", "daily");
        } else {
            dailyEl?.classList.remove("daily-puzzle-active");
            url.searchParams.delete("d");
        }

        window.history.replaceState({}, '', url.toString());
    }

    createLocalLink(path: string, queryParams: { [key: string]: string }, includeClient: boolean): string {
        if (path.substring(0) !== "/") {
            path = "/" + path;
        }

        let protocol = window.location.protocol;
        let host = window.location.host;
        /*if (host.indexOf("localhost") > -1) {
            host = "hexakai.com";
            protocol = "https://";
        }*/

        const currentUrlWithBParam = new URL(
            protocol + (host + path).replace(/hexakai\.com\/+/, "hexakai.com/")
        );

        for (const key in queryParams) {
            currentUrlWithBParam.searchParams.set(key, queryParams[key]);
        }

        if (includeClient && this.client !== Client.webBrowser) {
            currentUrlWithBParam.searchParams.set('c', this.client);
        } else {
            currentUrlWithBParam.searchParams.delete('c');
        }

        return currentUrlWithBParam.toString();
    }

    /**
     * This notifies subscribers that an "inner" navigation has occurred,
     * a navigation that is meaningful but not technically a page change
     */
    notifyInnerNavigationOccurred(): void {
        console.log(`[ClientService] notifying inner navigation occurred`);
        this.eventEmitter.emit('inner-navigation');
    }

    onInnerNavigationOccurred(callback: (evt: any) => void): void {
        this.eventEmitter.addListener('inner-navigation', async (evt: any) => {
            try {
                await callback(evt);
            } catch (err) {
                console.warn(`[ClientService] error caught in inner navigation callback`, err);
            }
        });
    }

    /**
     * Add client query param
     * @returns 
     */
    private interceptAppHref(): void {
        document.addEventListener("DOMContentLoaded", () => {
            document.body.addEventListener('click', (event: MouseEvent) => {
                const target = event.target as HTMLAnchorElement;

                // Check if the clicked element is an anchor and it has a href attribute
                if (target.tagName === 'A' && target.href) {
                    event.preventDefault();  // Stop the default navigation

                    const hrefUrl = new URL(target.href);
                    const currentDomain = window.location.hostname;

                    // Append a query parameter
                    if (hrefUrl.hostname === currentDomain && this.client !== Client.webBrowser) {
                        hrefUrl.searchParams.append('c', this.client);
                    }

                    let url = hrefUrl.toString();
                    if (this.config.urls.alterScheme && (target.target === '_blank' || hrefUrl.hostname !== currentDomain)) {
                        url = url.replace(hrefUrl.protocol, `hexakai-external:${hrefUrl.protocol}`);
                    }

                    console.log("[ClientService] opening url", url);

                    // Conditional navigation based on the target attribute
                    if (target.target === '_blank') {
                        // Open in a new tab if specified
                        window.open(url, '_blank');
                    } else {
                        // Navigate in the same tab
                        window.location.href = url;
                    }
                }
            }, true);
        });
    }

    private updateBodyStyles(): void {
        // update menu css to show current page
        if (this.config.body.fontOverride) {
            document.body.style.setProperty("--body-font", this.config.body.fontOverride);
        }

        if (this.config.gameBoard.boldCellValues) {
            document.body.style.setProperty("--hex-value-font-weight", "bold");
        }

        const currentPath = window.location.pathname.replace("/", "") || "app";
        const anchorElements: any[] = [...document.querySelectorAll("a")!];
        for (const link of anchorElements) {
            if (link.hostname !== window.location.hostname) {
                continue;
            }

            const hrefPath = new URL(link.href).pathname.replace("/", "");
            if (link.classList.contains("menu-href") && currentPath.indexOf(hrefPath) === 0) {
                link.classList.add("current-page");
            }
        }

        const nav = document.querySelector("nav")! as HTMLElement;

        if (this.config.body.navMenuSubmenuEnabled) {
            nav.classList.add("nav-scroll");
        } else {
            nav.classList.add("nav-noscroll");
            const navLinks = document.querySelector(".nav-links")! as HTMLElement;
            navLinks.style.setProperty("overflow-x", "hidden");
            for (const el of document.querySelectorAll(".menu-small")) {
                el.classList.add("hide-on-small");
            }
        }

        for (const el of document.querySelectorAll(".menu-small")) {
            el.classList.remove("menu-small");
        }
    }

    private updateFooterIdText(): void {
        const footerNameEl = document.getElementById("footer-client-text");
        if (footerNameEl) {
            footerNameEl.innerText = this.config.footer.clientText;
        }

        const bidEl = document.getElementById("b-id");
        if (bidEl) {
            bidEl.innerText = `B.${BID.id}`;
        }
    }
}
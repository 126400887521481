import { AdProviders, ClientConfig } from "../../models/client-config";

export const ANDROID_APP_CONFIG: ClientConfig = {
    body: {
        appBodyScrollDisabled: true,
        fullScreenEnabled: false,
        gameCompleteAdVisible: true,
        styledButton: {
            pointerUpCssForce: true
        },
        navMenuSubmenuEnabled: false
    },
    tutorial: {
        type: "interactive"
    },
    footer: {
        clientText: "Android",
        googleAdVisiblePercent: 100,
    },
    gameBoard: {
        thickBorders: false,
        boldCellValues: true,
        defaultHexHeight: "max(5vw, 5vh)",
        hexFontMultiplier: 0.31,
        colorInfoMessage: `To mark a cell with a color, double tap, or hold your finger on the cell until the color selection appears.`,
        zoomMessage: `To zoom in and out, use two fingers to make the board bigger or smaller, or use the icons on the top right.`,
        showAppMessage: false,
        showCellConstraintsHover: true,
        puzzleCompletionModal: {
            enabled: true
        },
        generatorPatternCreate: {
            enabled: true,
            experimentalEnabled: false
        },
        hexCell: {
            rightClickStrategy: 'contextmenu',
            numPencilsVisible: 3
        },
        pParamEnabled: false,
        defaultAlgorithm: 4
    },
    gameAchievements: {
        emitStrategy: 'emit-in-javascript',
        displayToast: false,
        displayAchievementsView: true
    },
    urls: {
        alterScheme: false,
    },
    ads: {
        requestDisplayPermission: {
            enabled: false,
        },
        adProviders: [{
            provider: AdProviders.google,
            percentage: 90
        }, {
            provider: AdProviders.bookOfTheMonth,
            percentage: 0
        }, {
            provider: AdProviders.authorBook,
            percentage: 10
        }],
        viewPercentage: 0
    },
    analytics: {
        logEvents: true
    },
    clientDispatcher: {
        readyResolveStrategy: 'ios'
    },
    dailyPuzzles: {
        future: false
    },
    controls: {
        window: false
    },
    themes: {
        extendedThemesEnabled: true
    },
    share: {
        navigatorEnabled: true
    },
    gameComplete: {
        mockStreak: false
    },
    hexakaiPlus: {
        enabled: false,
        publicApiKey: "rcb_ZFHETaoFhJjOxnPWmniKnNKctJOf",
        subscriptionUrlPrefix: "https://pay.rev.cat/cwlfluuvzdjupudd/"
    },
    userProfile: {
        enabled: false
    }
};
import { AdProviders, ClientConfig } from "../../models/client-config";
import { WEB_BROWSER_CONFIG } from "../prod/web-browser-config";

export const LOCALHOST_CONFIG: ClientConfig = {
    ...WEB_BROWSER_CONFIG,
    body: {
        ...WEB_BROWSER_CONFIG.body,
        gameCompleteAdVisible: true,
        styledButton: {
            pointerUpCssForce: false
        },
        navMenuSubmenuEnabled: true,
        fullScreenEnabled: false
    },
    tutorial: {
        type: "interactive"
    },
    gameBoard: {
        ...WEB_BROWSER_CONFIG.gameBoard,
        showCellConstraintsHover: true,
        generatorPatternCreate: {
            enabled: true,
            experimentalEnabled: true
        },
        hexCell: {
            rightClickStrategy: 'wait',
            numPencilsVisible: 3
        },
        pParamEnabled: true,
        defaultAlgorithm: 4
    },
    analytics: {
        logEvents: false
    },
    dailyPuzzles: {
        future: true
    },
    controls: {
        window: true
    },
    themes: {
        extendedThemesEnabled: true
    },
    share: {
        navigatorEnabled: false
    },
    ads: {
        requestDisplayPermission: {
            enabled: false,
        },
        adProviders: [{
            provider: AdProviders.bookOfTheMonth,
            percentage: 0
        }, {
            provider: AdProviders.authorBook,
            percentage: 0
        },
        {
            provider: AdProviders.google,
            percentage: 100
        }],
        viewPercentage: 0
    },
    gameComplete: {
        mockStreak: true
    },
    hexakaiPlus: {
        enabled: true,
        publicApiKey: "rcb_sb_frwaLqKBCKnuNNwCRMBBukXBK",
        subscriptionUrlPrefix: "https://pay.rev.cat/sandbox/gkugmlxkharmsjgu/"
    },
    userProfile: {
        enabled: true
    }
};